import("jquery")
  .then(function (jQuery) {
    (function ($) {
      $(".hamburger").click(function () {
        $(".hamburger, .mobile-menu").toggleClass("open");
      });

      $(".mobile-menu .menu-item").click(function(){
        $(".hamburger, .mobile-menu").toggleClass("open");
      });
    })(jQuery);
  });

